export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',
  FACET_BUTTON_CLASS: '',

  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',

  SEARCH_BOX_INPUT_CLASS: 'header-global-search-input',
  SEARCH_BOX_BUTTON_CLASS: 'button icon-left',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  PRODUCT_REPEATER_CLASS: 'product-grid grid grid-2-col grid-3-col-tablet grid-4-col-desktop',

  RELATED_PRODUCT_LIST_CLASS: 'grid grid-3-col-tablet grid-4-col-desktop',
  YOU_MAY_ALSO_LIKE: 'Related products',

  GARAGE_SIZE: '<span class="garage-size head-slot-cart-link-quantity" key="garage-size">{{size}}</span>',
};
